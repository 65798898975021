














































import coreApiClient from "@/services/apis/coreApiClient";
import printService from "@/services/printService";
import Vue from "vue";
import BaseBarcodeScanPage from "./BaseBarcodeScanPage.vue";
export default Vue.extend({
  components: { BaseBarcodeScanPage },
  data() {
    const self = this;
    return {
      dialogShown: false,
      dialogContentHtml: "",
      prepareHandler: async (barcode) => {
        return await coreApiClient.call("orderFulfillment", "preparePackage", {
          barcode,
        });
      },
      actionHandler: async (barcode, originResult, setting) => {
        const result = await coreApiClient.callRaw(
          "orderFulfillment",
          "package",
          {
            barcode,
          }
        );
        if (!result.done) {
          if (result.packageLot) {
            self.showInstructions(
              `Put the product at <b class="text-primary text-h3 red--text">slot ${result.packageLot.number}</b>`
            );
          } else {
            self.showInstructions("No slot found!");
          }
        } else {
          const order = result.order;
          {
            const url = coreApiClient.makeDownloadUrl(
              "orders",
              `@/downloadShippingLabel/${order._id}`
            );
            const printerItem = setting.printerItems.find(({ tags }) => {
              return tags.includes("shippingLabel");
            });
            if (!printerItem) {
              throw new Error(
                `No matched printer found for tags: shippingLabel`
              );
            }
            await printService.printPdf(printerItem.name, url);
          }
          if (order.extraShippingLabelKeys?.includes("documentsDouaniers")) {
            const url = coreApiClient.makeDownloadUrl(
              "orders",
              `@/downloadExtraShippingLabel/${order._id}/documentsDouaniers`
            );
            const printerItem = setting.printerItems.find(({ tags }) => {
              return tags.includes("shippingLabelDocumentsDouaniers");
            });
            if (!printerItem) {
              throw new Error(
                `No matched printer found for tags: shippingLabelDocumentsDouaniers`
              );
            }
            await printService.printPdf(printerItem.name, url, {
              size: { width: 8.3, height: 11.7 },
              units: "in",
            });
          }
          if (result.packageLot) {
            self.showInstructions(
              `Gather product(s) from <b class="text-primary text-h3 red--text">slot ${result.packageLot.number}</b> and put them in the package along with the shipping label(s)`
            );
          }
        }
        return true;
      },
    };
  },
  methods: {
    async showInstructions(content) {
      this.dialogContentHtml = content;
      this.dialogShown = true;
    },
  },
  created() {
    // this.showInstructions(
    //   `Put the product at <b class="text-primary text-h3 red--text">slot 1</b>`
    // );
    // this.showInstructions(
    //   `Gather product(s) from <b class="text-primary text-h3 red--text">slot 2</b> and put them in the package along with the shipping label(s)`
    // );
  },
});
