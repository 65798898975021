






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  props: ["barcode"],
  data() {
    return {
      tableOptions: null,
    };
  },
  async created() {
    this.tableOptions = this.makeTableOptions(this.barcode);
  },
  methods: {
    makeTableOptions(barcode) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: false,
          search: {
            ext: {
              hidden: true,
            },
          },
          topActionButtons: {
            showDisplaySetting: {
              ext: {
                hidden: true,
              },
            },
          },
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
            },
            createdTime: {
              text: "Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            user: {
              text: "User",
              sortable: true,
              options: {
                subProp: "user.email",
              },
            },
            actionType: {
              text: "Action",
              sortable: true,
              options: {
                label: true,
              },
            },
            actions: {},
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "actionScenario",
              operator: "equal_to",
              value: "OrderFulfillment",
            });
            options.filters.push({
              key: "actionParams.barcodes",
              operator: "equal_to",
              value: barcode,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "actionLogs",
                "findAll",
                options
              );
              return [items, count];
            },
          },
        },
      };
    },
  },
});
